* {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.centered_div {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 50px;
    transform: translate(-50%, -50%);
    /* You can also specify width and height for the div if needed */
    width: 300px;
    height: 300px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: rgba(227, 169, 169, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 20px;
}
.login_div {
    background-color: #4cd710;
}
.home_center {
    position: absolute;
    padding: 20px;
    margin-top: 10vh;
    /* You can also specify width and height for the div if needed */
    width: 80vw;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ccc;
    text-align: center;
}

.table {
    position: absolute;
    left: 50%;
    margin-top: 350px;
    transform: translate(-50%, -50%);
    /* You can also specify width and height for the div if needed */
    width: 800px;
    height: 500px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    text-align: center;
}

.header {
    margin-top: 0;
    position: fixed;
    width: 100%;
    height: 10vh;
    z-index: 10;
    border-bottom: 2px solid #000; /* 2px solid black border */
    background-color: #ffffff;
}

.input {
    margin-bottom: 20px;
}

.ant-input-number-handler-wrap {
    display: none;
}
.config_betRegion {
    display: flex;
    flex-direction: row;
    margin: 20px;
}
.config_betRegionLeft {
    flex: 33%;
}
.config_betRegionCenter {
    flex: 33%;
}
.config_betRegionRight {
    flex: 33%;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
